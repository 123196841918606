import { useState } from "react";
import ClusterTable from "./ClusterTable";
import ClusterTmapContainer from "./ClusterTMapContainer";
import { ClusterRouteResponse, SenderType } from "./types";
import Grid from "@mui/material/Grid";
import { ObpOptions } from "../../containers/cluster/utils";

type Props = {
  clustering: ClusterRouteResponse;
  senderInfo: SenderType;
  activeObpOption: ObpOptions;
};
function ClusteringTestbed({ clustering, senderInfo, activeObpOption }: Props) {
  const [expandedClusterId, setExpandedClusterId] = useState<string[]>([]);

  // 다수 서브 테이블 참조할 수 있게 변경
  const onToggleCluster = (clusterId: string) => {
    setExpandedClusterId(
      expandedClusterId.includes(clusterId)
        ? expandedClusterId.filter((id) => id !== clusterId)
        : [...expandedClusterId, clusterId]
    );
  };

  return (
    <Grid container spacing={3} padding={2}>
      <Grid item xs={12}>
        <ClusterTable
          clusters={clustering}
          onToggleCluster={onToggleCluster}
          expandedClusterId={expandedClusterId}
          senderInfo={senderInfo}
          activeObpOption={activeObpOption}
        />
      </Grid>
      <Grid item xs={12}>
        <ClusterTmapContainer clusters={clustering} />
      </Grid>
    </Grid>
  );
}

export default ClusteringTestbed;
