import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  ClusteringResultWithObpOptions,
  OBP_OPTIONS,
  ObpOptions,
} from "../../containers/cluster/utils";
import { ClusterRouteResponse, SenderType } from "./types";
import { getClusterResult } from "../../lib/api/orders";
import { format } from "date-fns";
import { finishGlobalLoading, startGlobalLoading } from "../../modules/loading";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Typography,
  Box,
  Button,
  Paper,
  Tabs,
  Tab,
} from "@mui/material";
import ClusteringTestbed from "./ClusteringTestbed";
import ClusterPolyLineContainer from "./ClusterPolyLineContainer";

interface ClusteringResultProps {
  clusteringResult: ClusteringResultWithObpOptions;
  senderInfo: SenderType;
}

// 탭 패널 컴포넌트
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`clustering-tabpanel-${index}`}
      aria-labelledby={`clustering-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function ClusteringResult({
  clusteringResult,
  senderInfo,
}: ClusteringResultProps) {
  const dispatch = useDispatch();
  const [activeObpOption, setActiveObpOption] = useState<ObpOptions>(
    () => (Object.keys(clusteringResult)[0] as ObpOptions) || "cluster_geo"
  );

  // Tmap 데이터
  const [activeTmapData, setActiveTmapData] = useState<ClusterRouteResponse>();

  // 탭 관련 상태 추가
  const [tabValue, setTabValue] = useState(0);

  const activePlainClusteringData = clusteringResult[activeObpOption];

  const onClusterRouteSearchClick = async () => {
    dispatch(startGlobalLoading());

    const mergedData = Object.values(activePlainClusteringData).flat();
    const { clientName, pickupRequestTime } = senderInfo;
    const { senderLatitude: latitude, senderLongitude: longitude } =
      mergedData[0];

    // cluster/result의 data
    const bodyTableData = mergedData.map((item) => {
      return {
        client_name: item.client_name,
        address: item.receiver,
        box: item.box,
        latitude: item.receiverLatitude,
        longitude: item.receiverLongitude,
        distance: item.distance,
        cluster: item.cluster,
        ...(item.lower &&
          item.upper && {
            complete_request_time: {
              lower: item.lower,
              upper: item.upper,
            },
          }),
      };
    });

    const tableResult = await getClusterResult({
      sender: {
        ...(clientName && { client_name: clientName }),
        pickup_request_time: format(pickupRequestTime, "yyyyMMddHHmm"),
        latitude,
        longitude,
      },
      data: bodyTableData,
    });
    setActiveTmapData(tableResult.data);
    setTabValue(1);
    dispatch(finishGlobalLoading());
  };

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // 옵션 변경 시 Tmap 데이터 초기화
    setActiveTmapData(undefined);
    setActiveObpOption(event.target.value as ObpOptions);
  };

  // 탭 변경 핸들러
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Paper elevation={2} sx={{ p: 3, mb: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              클러스터링 결과
            </Typography>
            <FormGroup row>
              {Object.keys(clusteringResult).map((option) => (
                <FormControlLabel
                  key={option}
                  value={option}
                  control={
                    <Checkbox
                      checked={activeObpOption === option}
                      onChange={handleOptionChange}
                      value={option}
                    />
                  }
                  label={OBP_OPTIONS[option as ObpOptions] || option}
                />
              ))}
            </FormGroup>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: { xs: "flex-start", md: "flex-end" },
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={onClusterRouteSearchClick}
            >
              클러스터 경로 검색
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {activeTmapData ? (
        <>
          <Paper elevation={2} sx={{ p: 3 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="clustering tabs"
                variant="fullWidth"
                sx={{
                  width: "50vw",
                  margin: "0 auto",
                  border: "1px solid #e0e0e0",
                  "& .MuiTab-root": {
                    fontWeight: "bold",
                    fontSize: "1.1rem",
                    padding: "12px 16px",
                    color: "lightgray",
                    "&.Mui-selected": {
                      color: "#000",
                      backgroundColor: "#e0e0e0",
                      border: 0,
                    },
                    "&:hover": {
                      backgroundColor: "#e0e0e0",
                    },
                    transition: "all 0.3s ease",
                  },
                  "& .MuiTabs-indicator": {
                    display: "none",
                  },
                }}
              >
                <Tab
                  label="옵션 데이터"
                  id="clustering-tab-0"
                  aria-controls="clustering-tabpanel-0"
                  sx={{ borderRadius: "4px 0 0 4px" }}
                />
                <Tab
                  label="클러스터 경로 데이터"
                  id="clustering-tab-1"
                  aria-controls="clustering-tabpanel-1"
                  sx={{ borderRadius: "0 4px 4px 0" }}
                />
              </Tabs>
            </Box>

            <TabPanel value={tabValue} index={0}>
              <ClusterPolyLineContainer
                key={activeObpOption}
                activePlainClusteringData={clusteringResult[activeObpOption]}
              />
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              {activeTmapData ? (
                <ClusteringTestbed
                  clustering={activeTmapData}
                  senderInfo={senderInfo}
                  activeObpOption={activeObpOption}
                />
              ) : (
                <Typography variant="body1" sx={{ p: 2 }}>
                  클러스터 경로 검색 버튼을 클릭하여 티맵 데이터를 로드하세요.
                </Typography>
              )}
            </TabPanel>
          </Paper>
        </>
      ) : activeObpOption && clusteringResult[activeObpOption] ? (
        <ClusterPolyLineContainer
          key={activeObpOption}
          activePlainClusteringData={clusteringResult[activeObpOption]}
        />
      ) : null}
    </>
  );
}

export default ClusteringResult;
