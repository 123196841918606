export type ClusterSubtableRow = [
  클러스터: string,
  배송순서: string,
  고객명: string,
  주소: string,
  박스: string,
  이동거리: string,
  위도: string,
  경도: string,
  구간별예정시간: string,
];

export type ClusterSubtableRows = ClusterSubtableRow[];

export type SenderType = typeof DEFAULT_CLUSTER_SENDER;
export const DEFAULT_CLUSTER_SENDER = {
  clientName: "",
  address: "",
  latitude: "",
  longitude: "",
  maxCluster: 1,
  pickupRequestTime: new Date(),
  pointType: "S",
  box: 0,
  distance: 0,
};
export type Geometry = PointGeometry | LineStringGeometry;
export interface PointGeometry {
  type: "Point";
  coordinates: [number, number];
}
interface LineStringGeometry {
  type: "LineString";
  coordinates: [number, number][];
}
export type StartProperty = {
  pointType: "S";
  index: string;
  viaPointId: string; // 경유지 id(PK)
  viaPointName: string; // 경유지 명칭
  arriveTime: string; // 배송지 차량 도착 예정시간
  completeTime: string; // 배송지 완료 예정시간 (YYYYMMDDHH24MISS) * 현재 배송지에 배송 완료 예정 시간
  distance: string; // 구간거리입니다. (m) 직전 지점에서 현 지점 까지의 거리
  deliveryTime: string; // 상하차 시간 (sec) * 현재 배송지의 상하차 소요시간
  waitTime: string; // 대기 시간 (sec) * 희망시간을 맞추기 위한 대기시간
};

export type BaseBreakpointProperty = {
  pointType: `B${number}`;
  index: string;
  viaPointId: string; //경유지 id(PK)
  viaPointName: string; //경유지 명칭
  viaDetailAddress: string; //경유지 상세주소
  distance: string; //구간거리입니다. (m) 직전 지점에서 현 지점 까지의 거리
  groupKey: string; //경유지 그룹키
  arriveTime: string; //배송지 차량 도착 예정시간
  completeTime: string; //배송지 완료 예정시간 (YYYYMMDDHH24MISS) * 현재 배송지에 배송 완료 예정 시간
  deliveryTime: string; //상하차 시간 (sec) * 현재 배송지의 상하차 소요시간
  waitTime: string; //대기 시간 (sec) * 희망시간을 맞추기 위한 대기시간
  completeRequestTime?: {
    // time window
    lower: string;
    upper: string;
  };
};

export type LineStringBreakpointProperty = BaseBreakpointProperty & {
  time: string; // 구간 통과 시간. (단위:초) 직점 지점에서 현 지점까지의 시간
  Fare: string; // 구간 요금. 직전 지점에서 현 지점까지의 구간 요금 정보.
  poiId: string; // 구간의 POI ID 정보.
  clientName: string; // 배송지 클라이언트 명칭
  address: string; // 배송지 주소
  box: number; // 배송지 박스 수
  latitude: string; // 배송지 위도
  longitude: string; // 배송지 경도
  completeRequestTime?: {
    // time window
    lower: string;
    upper: string;
  };
};

export type BreakpointProperty =
  | BaseBreakpointProperty
  | LineStringBreakpointProperty;

export type EndProperty = {
  pointType: "E";
  index: string;
  viaPointId: string; // 경유지 id(PK)
  viaPointName: string; // 경유지 명칭
  arriveTime: string; // 배송지 차량 도착 예정시간
  completeTime: string; // 배송지 완료 예정시간 (YYYYMMDDHH24MISS) * 현재 배송지에 배송 완료 예정 시간
  distance: string; // 구간거리입니다. (m) 직전 지점에서 현 지점 까지의 거리
  time: string; // 구간 통과 시간. (단위:초) 직점 지점에서 현 지점까지의 시간
  deliveryTime: string; // 상하차 시간 (sec) * 현재 배송지의 상하차 소요시간
  waitTime: string; // 대기 시간 (sec) * 희망시간을 맞추기 위한 대기시간
  Fare: string; // 구간 요금. 직전 지점에서 현 지점까지의 구간 요금 정보.
  poiId: string; // 구간의 POI ID 정보.
  clientName: string; // 배송지 클라이언트 명칭
  address: string; // 배송지 주소
  box: number; // 배송지 박스 수
  latitude: string; // 배송지 위도
  longitude: string; // 배송지 경도
  completeRequestTime?: {
    // time window
    lower: string;
    upper: string;
  };
};

export type OptimizationProperty =
  | StartProperty
  | BreakpointProperty
  | EndProperty;

export type OptimizationClusterItem = {
  geometry: Geometry;
  properties: OptimizationProperty;
  type: string;
};

export type PredictionStartProperty = {
  pointType: "S";
  address: null; // 도착지는 string
  clientName: string;
  latitude: string;
  longitude: string;
  arrivalTime: string; // 출발 시간(ISO 8601)
  departureTime: string; // 도착 시간(ISO 8601)
  totalDistance: number; // 경로 총 길이(단위: m)
  totalTime: number; // 경로 총 소요시간(단위: 초)
  totalFare: number; // 경로 총 요금(단위: 원)
  taxiFare: number; // 택시 예상 요금(단위: 원)
  index: number; // 경로 순번
  pointIndex: number; // 안내점 노드의 순번
  nextRoadName: string; // 다음 구간의 도로 명칭
  name: string; // 안내지점의 명칭
  description: string; // 길 안내 정보. 없으면 빈 스트링
  turnType: number; // 회전 타입
};

export type PredictionNexusProperty = {
  pointType: "N"; // 경유지
  index: number; // 경로 순번
  pointIndex: number; // 안내점 노드의 순번
  name: string; // 안내지점의 명칭
  description: string; // 길 안내 정보. 없으면 빈 스트링
  distance: number; // 구간 거리(단위: 미터)
  time: number; // 구간 통과 시간(단위: 초)
  roadType: number; // 도로 타입
  facilityType: number; // 구간 시설물 정보
};

export type PredictionEndProperty = {
  pointType: "E"; // 도착점
  address: string;
  clientName: string;
  box: number;
  latitude: string;
  longitude: string;
  index: number; // 경로 순번
  pointIndex: number; // 안내점 노드의 순번
  nextRoadName: string;
  name: string; // 안내지점의 명칭
  description: string; // 길 안내 정보. 없으면 빈 스트링
  turnType: number;
  distance: number; // 구간 거리(단위: 미터)
  time: number; // 구간 통과 시간(단위: 초)
  completeRequestTime?: {
    // time window
    lower: string;
    upper: string;
  };
};

export type PredictionPointProperty =
  | PredictionStartProperty
  | PredictionNexusProperty
  | PredictionEndProperty;

export type PredictionLineStringProperty = {
  name: string; // 구간의 명칭
  description: string; // 길 안내 정보
  distance: number; // 구간 거리(단위: 미터)
  time: number; // 구간 통과 시간(단위: 초)
  roadType: number; // 도로 타입
  facilityType: number; // 구간 시설물 정보
  index: number; // 경로 순번
  lineIndex: number; // 링크 순번
};

export type PredictionProperty =
  | PredictionPointProperty
  | PredictionLineStringProperty;

export type PredictionClusterItem = {
  geometry: Geometry;
  properties: PredictionProperty;
  type: string;
};
export type ClusterData =
  | {
      isMultiple: true;
      totalItem: number;
      totalBox: number;
      totalTime: string;
      totalDistance: string;
      items: OptimizationClusterItem[];
    }
  | {
      isMultiple: false;
      totalItem: number;
      totalBox: number;
      totalTime: string;
      totalDistance: number;
      items: PredictionClusterItem[];
    };
export type ClusterRouteResponse = {
  [key: string]: ClusterData; // 클러스터 번호를 키로 사용
};
