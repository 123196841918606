import { useState } from "react";
import ClusterUploadContainer from "./ClusterUploadContainer";

import ClusteringTestbed from "../../components/cluster/ClusteringTestbed";
import {
  SenderType,
  DEFAULT_CLUSTER_SENDER,
} from "../../components/cluster/types";
import { ClusteringResultWithObpOptions } from "./utils";
import ClusteringResult from "../../components/cluster/ClusteringResult";

function ClusterContainer() {
  const [clusteringResult, setClusteringResult] =
    useState<ClusteringResultWithObpOptions | null>(null);

  const [senderInfo, setSenderInfo] = useState<SenderType>(
    DEFAULT_CLUSTER_SENDER
  );

  if (clusteringResult === null) {
    return (
      <ClusterUploadContainer
        setClusteringResult={setClusteringResult}
        setSenderInfo={setSenderInfo}
        senderInfo={senderInfo}
      />
    );
  }

  return (
    <ClusteringResult
      clusteringResult={clusteringResult}
      senderInfo={senderInfo}
    />
  );

  // return <ClusteringTestbed clustering={clustering} senderInfo={senderInfo} />

  // return (
  //   <>
  //     {clustering == null ? (
  //       <ClusterUploadContainer
  //         setClustering={setClustering}
  //         setSenderInfo={setSenderInfo}
  //         senderInfo={senderInfo}
  //       />
  //     ) : (

  //     )}
  //   </>
  // );
}

export default ClusterContainer;
